import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
/*import Profile from '../views/Profile.vue'
import About from '../views/About.vue'*/
import Stacker from '../views/Stacker.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  /*
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
  },

  { path: '/product/:id/:category', component: () => import(/* webpackChunkName: "product" *-/ '../views/Product.vue') },
  */
  {
    path: '/stacker/:whenCard?/:willCard?',
    name: "Stacker",
    component: Stacker
  }/*,
  
  {
    path: '/about',
    name: 'About',
    meta: { transition: 'slide-right' },
    component: About
    /-*component: {
      template: About,
      created() {
        this.$watch(
          () => this.$route.params,
          (toParams, previousParams) => {
            console.log(toParams, previousParams);
            // react to route changes...
          }
        )
      },
    }
    */

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  //}
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

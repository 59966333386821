<template>
  
  <div class="d-flex flex-column justify-content-center hs-home-content" style="height:100%">
    <div class="row">
      <div class="col hs-intro">
        <div class="d-flex flex-column">
          <h1 class="hs-main-title">Habit Stacker</h1>
          <!--<hr class="hs-hr-gradient" size="4">-->
        </div>
        <div class="hs-slogan">A tool to build new habits by pairing what you do — <span class="hs-green-text">with something new.</span></div>
        <ol class="hs-instructions">
          <li>Deal cards to explore ideas of which habits to stack together.</li>
          <li>Swap and deal new cards to customise your pair.</li>
          <li>Share your card pair with others.</li>
        </ol>


        <!--<button class="btn btn-outline-dark hs-button" @click="$router.push('about')">Get started <i class="bi bi-chevron-double-right"></i></button>-->
        <!--<a type="button" class="btn btn-outline-dark hs-button" href="#/about">Get started <i class="bi bi-chevron-double-right"></i></a>-->
        <router-link
          to="/stacker"
          v-slot="{href, navigate}"
          >
            <button class="btn btn-outline-dark hs-button hs-get-started-button" :href="href" @click="navigate">
              <!--{{ route.name }}-->
              GET STARTED <span class="material-icons hs-icon-vam" style="margin-top: -2px;margin-left:-5px;">chevron_right</span>
            </button>
        </router-link>

      </div>
      <div class="col">
        <div class="hs-home-cards d-flex justify-content-center">
          <router-link to="/stacker/1/1" custom v-slot="{ navigate }">
            <div class="hs-card d-flex flex-column justify-content-end" @click="navigate">
              <div class="hs-card-header">WHEN I...</div>
              <div class="hs-card-text">
                <h3>Make a decision</h3>
                When I have a decision to make
              </div>
            </div>
          </router-link>
          <router-link to="/stacker/1/1" custom v-slot="{ navigate }">
            <div class="hs-card hs-card-color2 d-flex flex-column justify-content-end" @click="navigate">
              <div class="hs-card-header">I WILL...</div>
              <div class="hs-card-text">
                <h3>Visualise the data</h3>
                I will visualise the data before analysing or interpreting it"
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row hs-getting-started-mobile hs-mobile-only" style="margin-bottom:20px;">
      <div class="d-flex justify-content-center" >
        <router-link
          to="/stacker"
          v-slot="{href, navigate}"
          >
            <button class="btn btn-outline-dark hs-button" :href="href" @click="navigate">
              <!--{{ route.name }}-->
              GET STARTED <span class="material-icons hs-icon-vam" style="margin-top: -4px;margin-left:-5px;">chevron_right</span>
            </button>
        </router-link>
      </div>
    </div>
    
  </div>

</template>

<script>
export default {
  name: 'Home'
}
</script>

<style>

.hs-get-started-button {
  margin-left: 0;
}
.hs-home-cards .hs-card-text {
  font-size: 0.89rem !important;
}
.hs-home-cards .hs-card {
  cursor: pointer;
}
.hs-getting-started-mobile {
  margin-top:20px;
}
.hs-home-content .hs-hr-gradient {
  margin-bottom:10px;
}

@media (max-width: 991px) {  
  .hs-home-cards {
    margin-top:60px;
    
  }
  .hs-main-title {
    font-size: 2.5rem;
    margin-top:20px;
  }

  .hs-home-cards .hs-card div.hs-card-header {
    transform: scale(0.85) skew(-15deg);
    left: calc(50% - 60px);
  }

  .hs-home-cards .hs-card {
    transform: scale(0.85) rotate(-10deg);
  }

  .hs-home-cards .hs-card.hs-card-color2 {
    transform: scale(0.85) rotate(10deg);  
  }

  .hs-home-cards .hs-card div.hs-card-text h3 {
    font-size: 1.1rem;
  }

  .hs-home-cards .hs-card-text {
    font-size: 0.79rem !important;
  }
}

@media (max-width: 767.98px) {  
  .hs-home-cards {
    margin-top:20px;
  }
  .hs-home-content .hs-hr-gradient {
    width: 100% !important;
  }
  .hs-home-content {
    margin-top: 10px;
    justify-content: start !important
  }
  .hs-get-started-button {
    display: none;
  }
  .hs-main-title, .hs-slogan {
    text-align:center;
  }
  .hs-main-title {
    font-size: 3rem;
    margin-top:0px;
  }
  
}


</style>
<template>
  <div class="habit-stack-scene scene--card">
    <div v-bind:class="flipped ? 'habit-stack-card is-flipped': 'habit-stack-card'" ref="card">
      <div class="habit-stack-card__face habit-stack-card__face--front">
        <div class="hs-card d-flex flex-column justify-content-end" v-bind:class="cardWill ? 'hs-card-color2' : ''" v-bind:style="cardSpecific">
          <div class="hs-card-header">{{ cardHeader }}</div>
          <div class="hs-card-text">
            <h3>{{ cardTitle }}</h3>
            {{ cardContent }}
          </div>
        </div>
        
      </div>
      
    </div>
  </div>

</template>

<script>
export default {
  name: 'FlipCard',
  data: function() {
    return {
        flipped: false,
        cardTitle: null,
        cardContent: null,
        cardImage: null,
        cardHeader: "WHEN I...",
        cardWill: false
    };
  },
  props: {
    cardType: String,
    cardTitleProvided: String,
    cardContentProvided: String,
    cardImageProvided: String
  },
  methods: {
    flip: function(oCard) {
      //this.flipped = !this.flipped;
      this.initCard(oCard);
      /*setTimeout(function(){
        this.initCard(oCard);
      }.bind(this), 500);  
      */
    },
    initCard: function(oCard){
      this.cardTitle = oCard.title;
      this.cardContent = oCard.content;
      this.cardImage = oCard.image;
    }
  },
  computed: {
    cardSpecific() {
      return {
        
        "background-image": "url(" + this.cardImage + ")"
        //"background-repeat": "no-repeat",
        //"background-size": "cover"
        //height: `${this.height}px`
      };
    }
  },
  /*
  ready() {
  console.log("ready", this.cardType);
  },
  */
  created() {
    if(this.cardTitleProvided){
      this.cardTitle = this.cardTitleProvided;
    }
    if(this.cardContentProvided){
      this.cardContent = this.cardContentProvided;
    }
    if(this.cardImageProvided){
      this.cardImage = this.cardImageProvided;
    }
    switch(this.cardType){
      case "cardWill":
        this.cardHeader = "I WILL...";
        this.cardWill = true;
        break;
      default:
        break;
    }
  }
};
</script>

<style type='text/css' scoped>
.habit-stack-scene {
  height: 390px;
  width: 300px;
  margin-top: 40px;
  margin-bottom: 20px;
  perspective: 600px;
  cursor: pointer;
}

.habit-stack-scene .hs-card {
  height: 390px;
  width: 300px;
}

.habit-stack-card {
  width: 100%;
  height: 100%;
  /*transition: transform 1s;
  transform-style: preserve-3d;*/
  position: relative;
}

/*
.habit-stack-card.is-flipped {
  transform: rotateY(360deg);
}
*/

.habit-stack-scene .hs-card div.hs-card-header {
  left: 88px;
}

.habit-stack-scene:hover .hs-card {
  transform: scale(1.05);
}


@media (max-width: 767.98px) {  
  .habit-stack-scene {
    margin-top:0px;
    margin-bottom:0;
    height: 370px;
    cursor: initial;
  }
  .habit-stack-scene .hs-card, .habit-stack-scene:hover .hs-card {
    transform: scale(0.83);
  }
}


</style>
<template>

  <div id="stacker-container" class="d-flex flex-column justify-content-center stacker">
    <div class="d-flex flex-column justify-content-center align-items-center" style="margin-bottom:15px;">
        <h1 class="hs-stacker-title">Create your habit stack</h1>
        <!--<hr class="hs-hr-gradient" size="4">-->
        <span class="hs-stacker-description">Swap cards to build a new habit from something you already do.</span>
    </div>

    <carousel v-model="cardWhenIndex" v-on:update:model-value="changeCardWhenMobile" class="hs-mobile-carousel" :items-to-show="1.25" v-bind:itemsToScroll="cardsWhen.length" snapAlign="center" :wrapAround="true">
      <slide  v-for="card in cardsWhen" :key="card">
        <div class="col d-flex flex-column justify-content-center align-items-center">
              <HabitStackCard cardType="cardWhen" v-bind:cardTitleProvided="card.title" v-bind:cardContentProvided="card.content" v-bind:cardImageProvided="card.image"></HabitStackCard>
        </div>
      </slide>
    </carousel>
    <carousel class="hs-mobile-carousel" :items-to-show="1.25"  v-bind:itemsToScroll="cardsWill.length" v-model="cardWillIndex" v-on:update:model-value="changeCardWillMobile" snapAlign="center" :wrapAround="true">
      <slide v-for="card in cardsWill" :key="card">
        <div class="col d-flex flex-column justify-content-center align-items-center">
              <HabitStackCard cardType="cardWill" v-bind:cardTitleProvided="card.title" v-bind:cardContentProvided="card.content" v-bind:cardImageProvided="card.image"></HabitStackCard>
        </div>
      </slide>
    </carousel>
    
    <div class="row hs-stacker-card-container">
        <div class="col d-flex flex-column justify-content-center align-items-center">
            <HabitStackCard ref="cardWhenRef" cardType="cardWhen" v-on:click="changeCardWhen"></HabitStackCard>
        
            <a id="hs-swap-idea-left" v-on:click="changeCardWhen" href="#" onclick="return false;" class="hs-swap-idea"><span class="material-icons hs-icon-vam">sync</span> Swap card</a>
        </div>
    


        <div class="col d-flex flex-column justify-content-center align-items-center">
            <HabitStackCard ref="cardWillRef" cardType="cardWill" v-on:click="changeCardWill"></HabitStackCard>
            <!--
            <HabitStackCard ref="cardWillRef" v-bind:cardTitle="cardWhen.title" v-bind:cardContent="cardWhen.content">
            <template v-slot:front>
                <span>{{ cardWhen.title }}</span>
            </template>
            <template v-slot:back>
                <span>Next card content</span>
            </template>
            </HabitStackCard>
            -->

            <a id="hs-swap-idea-right" v-on:click="changeCardWill" href="#" onclick="return false;" class="hs-swap-idea"><span class="material-icons hs-icon-vam">sync</span> Swap card</a>
        </div>
    </div>

    <div class="d-flex justify-content-center hs-mobile-flex-column" style="margin:25px 0;">
        <button class="btn btn-outline-dark hs-button hs-habit-stack-button hs-button-minor" v-on:click="changeBothCards">      
            <span class="material-icons hs-icon-vam">sync</span> DEAL NEW PAIR
        </button>
        <button class="btn btn-outline-dark hs-button hs-habit-stack-button hs-button-minor" v-on:click="copyLinkAddress" >
          <span class="material-icons hs-icon-vam" style="font-size:1.4rem;">ios_share</span> SHARE CARD PAIR
        </button>
        <!--
        <a href="#" v-on:click="copyLinkAddress" onclick="return false;" class="hs-share-pair hs-swap-idea"><i class="bi bi-upload"></i>&nbsp;&nbsp;Share card pair</a>
        -->

    </div>

  </div>

</template>


<script>
import HabitStackCard from '@/components/HabitStackCard.vue';
import gsap from "gsap";
import ehCard1 from "../assets/img/wp-existing-habit-card1.jpg"
import ehCard2 from "../assets/img/wp-existing-habit-card2.jpg"
import ehCard3 from "../assets/img/wp-existing-habit-card3.jpg"
import ehCard4 from "../assets/img/wp-existing-habit-card4.jpg"
import ehCard5 from "../assets/img/wp-existing-habit-card5.jpg"
//import ehCard6 from "../assets/img/wp-existing-habit-card6.jpg"
import ehCard7 from "../assets/img/wp-existing-habit-card7.jpg"
//import ehCard8 from "../assets/img/wp-existing-habit-card8.jpg"
import ehCard9 from "../assets/img/wp-existing-habit-card9.jpg"
import ehCard10 from "../assets/img/wp-existing-habit-card10.jpg"
import ehCard11 from "../assets/img/wp-existing-habit-card11.jpg"
import ehCard12 from "../assets/img/wp-existing-habit-card12.jpg"
import ehCard13 from "../assets/img/wp-existing-habit-card13.jpg"
import ehCard14 from "../assets/img/wp-existing-habit-card14.jpg"
import ehCard15 from "../assets/img/wp-existing-habit-card15.jpg"
import nhCard1 from "../assets/img/wp-new-habit-card1.jpg"
import nhCard2 from "../assets/img/wp-new-habit-card2.jpg"
import nhCard3 from "../assets/img/wp-new-habit-card3.jpg"
import nhCard4 from "../assets/img/wp-new-habit-card4.jpg"
import nhCard5 from "../assets/img/wp-new-habit-card5.jpg"
import nhCard6 from "../assets/img/wp-new-habit-card6.jpg"
import nhCard7 from "../assets/img/wp-new-habit-card7.jpg"
import nhCard8 from "../assets/img/wp-new-habit-card8.jpg"
import nhCard9 from "../assets/img/wp-new-habit-card9.jpg"
import nhCard10 from "../assets/img/wp-new-habit-card10.jpg"
import nhCard11 from "../assets/img/wp-new-habit-card11.jpg"
import nhCard12 from "../assets/img/wp-new-habit-card12.jpg"
import nhCard13 from "../assets/img/wp-new-habit-card13.jpg"
import nhCard14 from "../assets/img/wp-new-habit-card14.jpg"
import nhCard15 from "../assets/img/wp-new-habit-card15.jpg"
import nhCard16 from "../assets/img/wp-new-habit-card16.jpg"
import nhCard17 from "../assets/img/wp-new-habit-card17.jpg"
import nhCard18 from "../assets/img/wp-new-habit-card18.jpg"
import nhCard19 from "../assets/img/wp-new-habit-card19.jpg"
import nhCard20 from "../assets/img/wp-new-habit-card20.png"
import nhCard21 from "../assets/img/wp-new-habit-card21.jpg"
import nhCard22 from "../assets/img/wp-new-habit-card22.jpg"
import nhCard23 from "../assets/img/wp-new-habit-card23.png"
import nhCard24 from "../assets/img/wp-new-habit-card24.png"
import nhCard25 from "../assets/img/wp-new-habit-card25.png"
import nhCard26 from "../assets/img/wp-new-habit-card26.png"
//import nhCard27 from "../assets/img/wp-new-habit-card27.jpg"
//import nhCard28 from "../assets/img/wp-new-habit-card28.jpg"
//import nhCard29 from "../assets/img/wp-new-habit-card29.jpg"
import nhCard30 from "../assets/img/wp-new-habit-card30.jpg"

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';

const shuffle = (array) => {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

const isMobile = () => window.outerWidth /*screen.width*/ <= 767;

/*
const CARDS_WHEN = shuffle([
    { id: "1", title: "Open a meeting", content: "When I am chairing a meeting", image: ehCard1 },
    { id: "2", title: "Start a new task", content: "When I begin a new task on my list", image: ehCard2 },
    { id: "3", title: "Start planning", content: "While I am planinng my next task / project", image: ehCard3 },
    { id: "4", title: "Log into my computer", content: "When I turn on my computer", image: ehCard4 },
    { id: "5", title: "Pause for a break", content: "When I stop to take a break or have lunch", image: ehCard5 },
    { id: "6", title: "Feel frustrated", content: "When I'm getting overwhelmed or frustrated", image: ehCard6 },
    { id: "7", title: "Change a process", content: "When I plan to improve a process", image: ehCard7 },
    { id: "8", title: "Log off work", content: "When I end my working day", image: ehCard8 },
    { id: "9", title: "Make a coffee", content: "When I break to make a coffee or tea", image: ehCard9 },
    { id: "10", title: "Face a difficult decision", content: "When I need to make a tough choice", image: ehCard10 }
]);
*/

const CARDS_WHEN = shuffle([
  { id: "1", title: "Make a decision", content: "When I have a decision to make", image: ehCard1 },
  { id: "2", title: "Present data", content: "When I present data to an audience", image: ehCard2 },
  { id: "3", title: "Open a data set", content: "When I open a new data set", image: ehCard3 },
  { id: "4", title: "Analyse data", content: "When I need to analyse and interpret data", image: ehCard4 },
  { id: "5", title: "See data in action", content: "When I'm presented with data", image: ehCard5 },
  { id: "7", title: "Consider customer needs", content: "When I need to understand customers", image: ehCard7 },
  { id: "9", title: "Have a problem to solve", content: "When I have a problem to solve", image: ehCard9 },
  { id: "10", title: "Start a new project", content: "When I start a new project", image: ehCard10 },
  { id: "11", title: "Connect with a colleague", content: "When I need to connect with a colleague", image: ehCard11 },
  { id: "12", title: "Have competing priorities", content: "When I have multiple important tasks", image: ehCard12 },
  { id: "13", title: "See an email risk", content: "When I receive a suspicious email", image: ehCard13 },
  { id: "14", title: "Use a cloud-based tool", content: "When I use a cloud-based tool", image: ehCard14 },
  { id: "15", title: "Set up a meeting", content: "When I set up a meeting", image: ehCard15 }
]);




/*
const CARDS_WILL = shuffle([
    { id: "1", title: "Clarify the intent", content: "Share the purpose of the meeting / priority / task", image: nhCard1 },
    { id: "2", title: "Question the outcome", content: "Ask myself, will this process get us to the right outcome?", image: nhCard2 },
    { id: "3", title: "Record the objectives", content: "Write down the measures I/we will use to know if we are effective in our aim", image: nhCard3 },
    { id: "4", title: "Consider client views", content: "Put myself in the shoes of the client to understand their point of view", image: nhCard4 },
    { id: "5", title: "Consider the consequences", content: "Pause and consider what the unintended consequences might be", image: nhCard5 },
    { id: "6", title: "Reflect on \"why?\"", content: "Reflect on one thing that didn't go well and ask myself \"why?\"", image: nhCard6 },
    { id: "7", title: "Assess something new", content: "Identify a new situation that came up and consider how I handled it", image: nhCard7 },
    { id: "8", title: "Talk it through", content: "Seek different opinions from two of my peers before making my decision", image: nhCard8 },
    { id: "9", title: "Identify the risks", content: "Consider what the biggests risks in the process are", image: nhCard9 },
    { id: "10", title: "Tune into my gut", content: "Listen to my 'spidey sense' to identify something that doesn't feel right", image: nhCard10 }
]);
*/

const CARDS_WILL = shuffle([
  { id: "1", title: "Visualise the data", content: "I will visualise the data before analysing or interpreting it", image: nhCard1 },
  { id: "2", title: "Use multiple sources", content: "I will validate accuracy with multiple sources of data", image: nhCard2 },
  { id: "3", title: "Consider the relevance", content: "I will consider the data's relevance to my situation", image: nhCard3 },
  { id: "4", title: "Explain the relevance", content: "I will explaine the data's relevance, not just the numbers", image: nhCard4 },
  { id: "5", title: "Make it relevant", content: "I will think about the story the data is trying to tell", image: nhCard5 },
  { id: "6", title: "Use accurate terms", content: "I will use accurate terminology to describe the data", image: nhCard6 },
  { id: "7", title: "Fine one extra source", content: "I will find and apply an extra source of relevant data", image: nhCard7 },
  { id: "8", title: "Do my own analysis", content: "I will apply 1 type of analysis to generate a new insight", image: nhCard8 },
  { id: "9", title: "Validate the source", content: "I will validate that the data came from a trusted source", image: nhCard9 },
  { id: "10", title: "Find relevant data", content: "I will locate and select data relevant to needs", image: nhCard10 },
  { id: "11", title: "Name and file", content: "I will name and file the data appropriately", image: nhCard11 },
  { id: "12", title: "Check the collection date", content: "I will check the date when that data was collected", image: nhCard12 },
  { id: "13", title: "De-identify it", content: "I will remove any personally identifying information", image: nhCard13 },
  { id: "14", title: "Seek conflicting information", content: "I will actively seek out conflicting information", image: nhCard14 },
  { id: "15", title: "Consider the customer", content: "I will ask myself 'what does this mean for our customers?'", image: nhCard15 },
  { id: "16", title: "Make it real", content: "I will include an example of how this impacts the audience", image: nhCard16 },
  { id: "17", title: "Share my insights", content: "I will share my insights with another person/team", image: nhCard17 },
  { id: "18", title: "Consider the role of data", content: "I will look at how using data contributed to success", image: nhCard18 },
  { id: "19", title: "Search for customer data", content: "I will search for relevant customer data", image: nhCard19 },
  { id: "20", title: "Check the feedback", content: "I will look for and analyse feedback data", image: nhCard20 },
  { id: "21", title: "Change my approach", content: "I will change my approach based on data insights", image: nhCard21 },
  { id: "22", title: "Use a digital tool", content: "I will search for the most appropriate digital tool", image: nhCard22 },
  { id: "23", title: "Collect data", content: "I will collect the relevant facts and data", image: nhCard23 },
  { id: "24", title: "Understand the problem", content: "I will define a problem statement before seeking solutions ", image: nhCard24 },
  { id: "25", title: "Reach out digitally", content: "I will Use an approved digital tool like MS Teams ", image: nhCard25 },
  { id: "26", title: "Report concerns to ITS", content: "I will report the suspcious actvity to relevant department", image: nhCard26 },
  { id: "30", title: "Prioritise my tasks", content: "I will prioritise my tasks based on urgency and importance", image: nhCard30 }
]);

const PAIR_EXCLUSIONS = [];//[{ whenId: "1", willId: "6" }];

var fnGetNextIndex = (bWill, iOtherCardIndex, iCurrentIndex, bRandom) => {
    var aSelectionTable = bWill ? CARDS_WILL : CARDS_WHEN;
    var aOtherTable  = bWill ? CARDS_WHEN : CARDS_WILL;
    var sComparePairId = bWill ? "whenId" : "willId";
    var sCompareSuggestedId = bWill ? "willId" : "whenId";

    var fnDetermine = (iCurrentCardIndex, iRecursiveCallCount) => {
        
        const fnRandom = () => {
          
          var array = aSelectionTable.map((s,i) => i);
          array.splice(iCurrentCardIndex, 1);
          var num = Math.floor(Math.random() * array.length);
          return array[num];
        };

        var iCardIndex = iCurrentCardIndex;
        
        if(typeof iCardIndex !== "number"){
            iCardIndex = -1;
        }
        if(bRandom){
          iCardIndex = fnRandom();
        } else {
          iCardIndex += 1;
        }
        if(iCardIndex > ( ( bWill ? aSelectionTable.length : aSelectionTable.length ) - 1 )){
            iCardIndex = 0;
        }

        if(typeof iOtherCardIndex === "number" && iOtherCardIndex >= 0 && iRecursiveCallCount < 10){
            // Make sure not a restricted pair
            var sSuggestedId = aSelectionTable[iCardIndex].id;
            var sPairId = aOtherTable[iOtherCardIndex].id;

            var bExclude = PAIR_EXCLUSIONS.some(p => p[sComparePairId] === sPairId && p[sCompareSuggestedId] === sSuggestedId);
            if(bExclude){
                return fnDetermine(iCardIndex, iRecursiveCallCount + 1);
            }
        }
        return iCardIndex;
    
    }

    return fnDetermine(iCurrentIndex, 0);//bWill ? this.cardWillIndex : this.cardWhenIndex, 0);
};

export default {
  name: 'Stacker',
  components: {
    HabitStackCard,
    Carousel,
    Slide
  
  },
  data: function () {
    return {
      
      cardsWhen: CARDS_WHEN,
      cardsWill: CARDS_WILL,
      cardWhenIndex: 0,
      cardWhenFlipped: false,
      cardWillIndex: 0,
      cardWillFlipped: false,
      count: 0,
      animationWhenCount: 0,
      animationWillCount: 0
    }
  },
  methods: {
    

    _animateCard: function(card, bLeftCard){
        var exitNode  = card.cloneNode(true),
            sCountVar = bLeftCard ? "animationWhenCount" : "animationWillCount",
            that = this;

        const anyAnimationRunning = () => that.animationWhenCount > 0 || that.animationWillCount > 0;

        exitNode.style.position="absolute";

        if(!anyAnimationRunning()){
          var container = document.getElementById("stacker-container");
          //container.style.maxHeight = (window.innerHeight - 60) + "px";
          container.style.overflow = "hidden";
        }


        card.parentNode.insertBefore(exitNode, card.nextSibling);
        this[sCountVar] += 1;

        var swapButton = document.getElementById("hs-swap-idea-" + (bLeftCard ? "left" : "right"));
        swapButton.style.visibility="hidden";

        gsap.fromTo(card, {rotation: bLeftCard ? 30 : -30, rotateY: bLeftCard ? -90 : 90, x: bLeftCard ? -100 : 100, y: 480, opacity: 0.0, duration: 0.5}, {rotation: 0, rotateY: 0, x:0, y: 0, opacity: 1, duration: 1, onComplete: function(){
          
          
          if(!anyAnimationRunning()){
            var container = document.getElementById("stacker-container");
            //container.style.maxHeight = null;
            container.style.overflow = null;
          }

        }.bind(this)});
        gsap.to(exitNode, {rotation: bLeftCard ? -30 : 30, x: bLeftCard ? -300 : 300, y: -680, opacity: 0.8, duration: 0.8, onComplete: function(){
          this[sCountVar] -= 1;
          if(this[sCountVar] == 0){
            swapButton.style.visibility="initial";
          }
          exitNode.remove();  
        }.bind(this)});
        
    },

    _animateCardLeft: function(){
        this._animateCard(this.$refs.cardWhenRef.$el, true);
    },

    _animateCardRight: function(){
        this._animateCard(this.$refs.cardWillRef.$el, false);
    },
    changeCardWhenMobile: function(iNewIndex){
      this.$router.push({ name: "Stacker", params: { whenCard: this.cardsWhen[iNewIndex].id, willCard: this.cardsWill[this.cardWillIndex].id } });
    },
    changeCardWillMobile: function(iNewIndex){
      this.$router.push({ name: "Stacker", params: { whenCard: this.cardsWhen[this.cardWhenIndex].id, willCard: this.cardsWill[iNewIndex].id } });
    },
    changeCardWhen: function(){
      if(isMobile()){
        return;
      }
      this._animateCardLeft();

      this.$router.push({ name: "Stacker", params: { whenCard: this.cardsWhen[fnGetNextIndex(false, this.cardWillIndex, this.cardWhenIndex)].id, willCard: this.cardsWill[this.cardWillIndex].id}});
    },
    changeCardWill: function(){
        if(isMobile()){
          return;
        }
        this._animateCardRight();

        this.$router.push({ name: "Stacker", params: { whenCard: this.cardsWhen[this.cardWhenIndex].id, willCard: this.cardsWill[fnGetNextIndex(true, this.cardWhenIndex, this.cardWillIndex)].id}});
    },
    changeBothCards: function(){
        if(!isMobile()){
          this._animateCardLeft();
          this._animateCardRight();
        }

        // Randomly select when changing both cards
        var iNextCardWhenIndex = fnGetNextIndex(false, this.cardWillIndex, this.cardWhenIndex, true);

        this.$router.push({ name: "Stacker", params: { whenCard: this.cardsWhen[iNextCardWhenIndex].id, willCard: this.cardsWill[fnGetNextIndex(true, iNextCardWhenIndex, this.cardWillIndex, true)].id}});
    },
    /*
    toggleCard: function(card) {
      card.flipped = !card.flipped;
    },
    */

    copyLinkAddress: function(){
        navigator.clipboard.writeText(window.location.href);
        
        this.$toast.info("Link copied. Paste in email to share with team.", {
            position: isMobile() ? "bottom" : "bottom-right",
            duration: 2000
        });

        //window.open("mailto:?subject=Habit stack pair&body=" + window.location.href);
    }
  },
  /*
  computed: {
    boxStyle() {
      return {
        "background-color": this.cardWhen.color,
        height: "100px",
        width: "100px",
        //height: `${this.height}px`
      };
    }
  },
  */
  beforeRouteEnter(to, from, next) {
    if(!to.params.whenCard || !to.params.willCard){
        var iWhenIndex = fnGetNextIndex(false, null, null);
        var iWillIndex = fnGetNextIndex(true, iWhenIndex, null);
        next("/stacker/" + CARDS_WHEN[iWhenIndex].id + "/" + CARDS_WILL[iWillIndex].id);
        return;
    }
    next(vm => {
        var whenCard = vm.cardsWhen.find(card => card.id === to.params.whenCard);
        var willCard = vm.cardsWill.find(card => card.id === to.params.willCard);
        vm.cardWillIndex = vm.cardsWill.indexOf(willCard);
        vm.cardWhenIndex = vm.cardsWhen.indexOf(whenCard);
        vm.$refs.cardWillRef.initCard(vm.cardsWill[vm.cardWillIndex]);
        vm.$refs.cardWhenRef.initCard(vm.cardsWhen[vm.cardWhenIndex]);
    });
    
    //next();
  },
  beforeRouteUpdate(to, from, next) {
    if(to.params.whenCard){
        var whenCard = this.cardsWhen.find(card => card.id === to.params.whenCard);
        if(whenCard && this.cardWhenIndex !== this.cardsWhen.indexOf(whenCard)){
            this.cardWhenIndex = this.cardsWhen.indexOf(whenCard);
            this.$refs.cardWhenRef.flip(whenCard);
        }
    }
    if(to.params.willCard){
        var willCard = this.cardsWill.find(card => card.id === to.params.willCard);
        if(willCard && this.cardWillIndex !== this.cardsWill.indexOf(willCard)){
            this.cardWillIndex = this.cardsWill.indexOf(willCard);
            this.$refs.cardWillRef.flip(willCard);
        }
    }

    next();
  },
  beforeRouteLeave(to, from, next) {
    next();
  }
  /*,
  setup(props, context) {
    console.log(props);
    console.log(context);
  }*/
  /*,
  created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        // react to route changes...
        console.log(toParams, previousParams);
      }
    )
  }*//*,
  async beforeRouteUpdate(to, from) {
    // react to route changes...
    console.log(to,from);
    
  }*/
}
</script>

<style>

:root {
    --hs-stacker-text-color: #0D4749;
    --hs-stacker-link-color: #44546A;
}

.hs-stacker-title {
    color: var(--hs-stacker-text-color);
    font-weight: bold;
    font-size: 2rem;
    margin-top: 20px;
}

.stacker .hs-hr-gradient {
  width:400px;
  margin:0;
}

.hs-stacker-description {
  max-width:400px;
  text-align:center;
  margin-top:5px;
  padding:0 10px;
  color:#44546A;
  font-size: 1.1rem;
  font-weight: 500;
}

.hs-stacker-card-container {
  position: relative;
}

.hs-swap-idea {
    color: var(--hs-deloitte-green-2);
    text-decoration:none;
    font-size: 1.1rem;
}

.hs-swap-idea:hover {
    /*text-decoration: underline;*/
    color: var(--hs-deloitte-green-1);
}

.hs-habit-stack-button {
  
  box-shadow: none;
  border-width: 2px;
  font-weight: 500;
}

.hs-share-pair {
  position:absolute;
  right:0;
  margin-top:35px;
  font-size:0.9rem;
  text-decoration:none;
}

.hs-mobile-carousel {
  display: none;
}

@media (max-width: 767.98px) {  
    .hs-stacker-card-container {
      display: none;
    }
    .hs-stacker-card-container .hs-swap-idea {
        display: none;
    }
    .hs-stacker-title {
      font-size: 1.8rem;
    }
    .stacker .hs-hr-gradient {
      width: 100%;
    }
    .hs-stacker-description {
      font-size: 0.85rem;
    }
    .hs-share-pair {
      position: initial;
      text-align: center;
      margin-top:0;
      margin-bottom:20px;
    }
    .hs-mobile-flex-column {
 
      flex-direction: column!important;

    }
    .hs-mobile-carousel {
      display: block;
    }
    .carousel__track li:not(.carousel__slide--active){
      opacity: 0.5;
    }
}

</style>
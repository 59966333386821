<template>

<div style="height:100%;overflow-y:scroll;overflow-x:hidden;">
  <div ref="hsMobileBlocker" style="position:fixed;width:100%;height:100%;z-index:101;background-color:black;opacity:90%;display:none;"></div>

  <header class="p-3 bg-dark text-white sticky-top">
      <div class="container">
        <div class="d-flex flex-wrap align-items-center justify-content-between">
          <div id="app-logo">
            
            <a href="#"><img id="deloitte-logo" src="./assets/img/deloitte-logo.svg" alt="Deloitte Logo" /></a>
            <!--<a href="#">
              <img id="behaviour-first-logo" src="./assets/img/behaviour-first-logo.svg" alt="Behaviour First Logo" />
            </a>-->
          </div>

          
          <div class="text-end">
            
            
            <div ref="hsDropdownHoverable" class="dropdown-hoverable">
              <a href="#" class="dropdown-trigger" onclick="return false;">
                LEARN MORE
                <span class="material-icons hs-icon-vam hs-small-padding hs-nav-down-arrow">keyboard_arrow_down</span>
                <span class="material-icons hs-icon-vam hs-small-padding hs-nav-up-arrow">keyboard_arrow_up</span>
                
              </a>
              <span class="material-icons hs-mobile-only hs-hamburger-menu-button" style="font-size:1.5rem;margin-right:-10px;cursor:pointer;" v-bind:style="hamburgerStyle" @click="mobileMenu">menu</span>
              <!--<i class="bi bi-list hs-mobile-only hs-hamburger-menu-button" style="font-size:1.5rem;margin-right:-10px;cursor:pointer;" v-bind:style="hamburgerStyle" @click="mobileMenu"></i>-->
        
              <div ref="hsDropdownHoverableMenu" class="dropdown-hoverable-menu">
                <div class="dropdown-hoverable-menu-items text-start">
                  <a href="https://jamesclear.com/habit-stacking" target="_blank" class="dropdown-item-link">
                    <strong>Why it works</strong>
                    How to Build New Habits by Taking Advantage of Old Ones
                  </a>
                  <a href="https://www2.deloitte.com/au/en/pages/human-capital/solutions/behaviour-first-change.html" target="_blank" class="dropdown-item-link">
                    <strong>Behaviour First</strong>
                    Closing the gap between intention and action, one nudge at a time
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </header>
    <div id="hs-content-container" role="main" class="container hs-content-container">
      <router-view></router-view>
    </div>
</div>


<!--<router-view v-slot="{ Component }">
  <transition name="scale-slide">
    <component :is="Component" />
  </transition>
</router-view>-->
  
</template>

<style>

@import './assets/css/main.css';

.hs-mobile-only {
  display:none;
}

.hs-content-container {
  height:calc(100% - 60px);
  min-height:580px;
}

@media (max-width: 767.98px) {  
    .hs-content-container {
        height: auto;
    }
    .dropdown-hoverable a.dropdown-trigger {
        display: none;
    }
    .hs-mobile-only {
      display:block;
    }
    .dropdown-hoverable-menu { 
      left: -200px;
      top: 27px;
    }
    /*.hs-hamburger-menu-button:active,.hs-hamburger-menu-button:focus,.hs-hamburger-menu-button:hover, .dropdown-hoverable:hover .hs-hamburger-menu-button {
      color: #76CA56;
    }*/
    .dropdown-hoverable:hover .dropdown-hoverable-menu {
      visibility: hidden;
      opacity: 0;
    }

}


</style>

<script>
//import deloitteLogo from '@/assets/img/deloitte-logo.svg';
export default {
  name: "App",
  data: function(){
    return {
      mobileMenuOpen: false
    };
  },
  computed: {
    hamburgerStyle() {
      if(this.mobileMenuOpen){
        return {
          "color": "#76CA56"
        };
      }
      return {

      };
    }
  },
  methods: {
    mobileMenu: function(){
      if(this.mobileMenuOpen){
        this.mobileMenuOpen = false;
      } else {
        this.mobileMenuOpen = true;
        this.$refs.hsMobileBlocker.style.display="block";
        this.$refs.hsDropdownHoverableMenu.style.visibility="visible";
        this.$refs.hsDropdownHoverableMenu.style.opacity="1";
        var fnCloseBlocker = () => {
          setTimeout(function(){
            this.$refs.hsMobileBlocker.style.display="none";
            this.$refs.hsDropdownHoverableMenu.style.visibility="hidden";
            this.$refs.hsDropdownHoverableMenu.style.opacity="0";
            document.body.removeEventListener("click", fnCloseBlocker, true);
            this.mobileMenuOpen = false;
          }.bind(this), 0);
        };
        document.body.addEventListener("click", fnCloseBlocker, true); 
      }

    }
  }
}
</script>